import AllTubbies from "../images/alltubbies.png";
import Stars1 from "../images/stars/stars1.png";
import Stars2 from "../images/stars/stars2.png";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import ConstructionTubby from "../images/constr.png";
import ConstructionSnoggle from "../images/construction_snoggle.png";
import TubbyLogo from "../images/tubby_logo00.svg";
const Home = () => {
  return (
    <div>
      <div style={{ maxHeight: "100vh" }} className="OuterContainer">
        <div className="BorderContainer">
          <div className="MiddleLogo">
            <img src={TubbyLogo} />
          </div>
          <div className="AbsoluteCentered">
            <img src={ConstructionTubby} className="ConstructionTubby" />
            <p className="Subheader1" style={{ marginTop: "10px" }}>
              OUR WEBSITE IS UNDER CONSTRUCTION. COME BACK SOON!
            </p>
            <a
              href="https://tubbycatsshop.myshopify.com/"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <button className="LinkButtond">
                <p>Shop Tubby Merch</p>
              </button>
            </a>
            {/* <p>SHOP TUBBY MERCH♡</p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
